import React from "react";
import GlobalContext from "./GlobalContext";

import axios from "axios";
import Swal from "sweetalert2";
const AppContext = (props) => {
  const [logo, setLogo] = React.useState();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    const key = new URLSearchParams(window.location.search).get(
      "partnership_id"
    );

    if(key === null){
      setTimeout(function(){
      Swal.fire({
        icon: 'error',
        text: "Veuillez vous connecter s'il vous plait",
        showConfirmButton: false,
        timer: 3000,
      })

      .then(() => {
        window.location = `${process.env.REACT_APP_DISTRIBUTEUR_APIURL}/#/sign-in`
    })  
  }, 2000)
    }
    const apiKey = key ? key : process.env.REACT_APP_API_KEY;
    localStorage.setItem("api_key", apiKey);
    let config = {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    };
    axios
      .post(`${process.env.REACT_APP_GEOPROD_APIURL}login`, {}, config)
      .then((res) => {
        localStorage.setItem("token", res.data.Acces_Token);
        axios
          .get(
            `${process.env.REACT_APP_CLOUD_GEOPROD_APIURL}/catalogue_app/get_lien_tarificateur`,
            {
              headers: {
                idSession: res.data.Acces_Token,
              },
            }
          )
          .then((res) => {
            setData(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
        axios
          .get(
            `${process.env.REACT_APP_GEOPROD_APIURL}organismes/get_by_id/${res.data.user.entreprise_id}`,
            {
              headers: {
                idSession: res.data.Acces_Token,
              },
            }
          )
          .then((res) => {
            setLogo(res.data.logo);
          })
          .catch((err) => console.log(err));
      });
  }, []);

  return (
    <GlobalContext.Provider value={{ logo, data }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
