import React from "react";
import classes from "./Card.module.css";

const Card = (props) => {
  const { titre, logo, url } = props;

  return (
    <div
      className={classes.card}
      onClick={() => {
        window.open(url);
      }}
    >
      <img src={logo} alt="logo" className={classes.logo}></img>
      <h1 className={classes.titre}>{titre}</h1>
    </div>
  );
};

export default Card;
