import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Catalogue from "./pages/Catalogue/Catalogue";
import AppContext from "./context/AppContext";

function App() {
  return (
    <AppContext>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Catalogue />} />
        </Routes>
      </BrowserRouter>
    </AppContext>
  );
}

export default App;
